import React from "react";
import { UseFormRegister, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../../Types/Form.types";
import { generateFieldsetId } from "../../../../utils/formHelpers";

interface AttendingFormProps {
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
}

const AttendingForm: React.FC<AttendingFormProps> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <fieldset id={generateFieldsetId("attending")}>
      <legend>{t("section.rsvp.form.attending.legend")}</legend>
      <label>
        <input
          type="radio"
          id="attendingYes"
          value="yes"
          aria-labelledby={generateFieldsetId("attendingYes")}
          {...register("attending", {
            required: t("section.rsvp.form.attending.required"),
          })}
        />
        <label
          htmlFor="attendingYes"
          aria-label={generateFieldsetId("attendingYes")}
        >
          {t("section.rsvp.form.attending.yes")}
        </label>
      </label>
      <label>
        <input
          type="radio"
          id="attendingNo"
          value="no"
          aria-labelledby={generateFieldsetId("attendingNo")}
          {...register("attending", {
            required: t("section.rsvp.form.attending.required"),
          })}
        />
        <label
          htmlFor="attendingNo"
          aria-label={generateFieldsetId("attendingNo")}
        >
          {t("section.rsvp.form.attending.no")}
        </label>
      </label>
      {errors.attending && (
        <p className="rsvp-form-errors">{errors.attending.message}</p>
      )}
    </fieldset>
  );
};

export default AttendingForm;
