import React from "react";
import { motion } from "framer-motion";

interface ToggleProps {
  beforeText?: string;
  afterText?: string;
  isOn: boolean;
  onToggle: () => void;
}

const Toggle: React.FC<ToggleProps> = ({
  beforeText,
  afterText,
  isOn,
  onToggle,
}) => {
  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30,
  };

  return (
    <>
      <p>{beforeText}</p>
      <div
        className={`switch ${isOn ? "on" : "off"}`}
        data-ison={isOn}
        onClick={onToggle}
        aria-label="toggle-button"
      >
        <motion.div className="handle" layout transition={spring} />
      </div>
      <p>{afterText}</p>
    </>
  );
};

export default Toggle;
