import React, { useState } from "react";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { generateFieldsetId } from "../../../utils/formHelpers";
import { FormValues } from "../../../Types/Form.types";
import { sendEmail } from "../../../services/emailService";
import AttendingForm from "./subforms/AttendingForm";
import AdditionalInfoForm from "./subforms/AdditionalInfoForm";

const RsvpForm = () => {
  const { t, i18n } = useTranslation();
  const [status, setStatus] = useState<"idle" | "success" | "error">("idle");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      attending: "no",
      stayingOnSite: "no",
      dietaryRequirements: "no",
      otherGuests: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "otherGuests",
  });

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    try {
      setStatus("idle");
      setSubmitDisabled(true);
      setErrorMessage("");
      await sendEmail(data, t, i18n);
      setStatus("success");
      reset();
    } catch (error) {
      console.log(error);
      setStatus("error");
      setErrorMessage(t("section.rsvp.emailErrorMessage"));
      setTimeout(() => {
        setStatus("idle");
        setSubmitDisabled(false);
        setErrorMessage("");
      }, 3000);
    }
  };

  const dietaryRequirements = watch("dietaryRequirements");

  return (
    <form
      id="rsvpForm"
      className="full-width-background"
      onSubmit={handleSubmit(onSubmit)}
    >
      {status === "success" && (
        <p className="rsvp-form-success">
          {t("section.rsvp.emailSuccessMessage")}
        </p>
      )}
      {status === "error" && (
        <p className="rsvp-form-errors rsvp-form-email-error">
          {errorMessage || t("section.rsvp.emailErrorMessage")}
        </p>
      )}

      {/* Primary Guest */}
      <div className="rsvp-form-block">
        <fieldset id={generateFieldsetId("fullName")}>
          <legend>{t("section.rsvp.form.fullName.legend")}</legend>
          <label htmlFor="fullNameInput">
            <input
              id="fullNameInput"
              type="text"
              placeholder={t("section.rsvp.form.fullName.placeholder")}
              autoComplete="name"
              aria-labelledby={generateFieldsetId("fullName")}
              {...register("fullName", {
                required: t("section.rsvp.form.fullName.required"),
              })}
            />
          </label>
          {errors.fullName && (
            <p className="rsvp-form-errors">{errors.fullName.message}</p>
          )}
        </fieldset>
        <fieldset id={generateFieldsetId("email")}>
          <legend>{t("section.rsvp.form.email.legend")}</legend>
          <label htmlFor="emailInput">
            <input
              id="emailInput"
              type="email"
              placeholder={t("section.rsvp.form.email.placeholder")}
              autoComplete="email"
              aria-labelledby={generateFieldsetId("email")}
              {...register("email", {
                required: t("section.rsvp.form.email.required"),
              })}
            />
          </label>
          {errors.email && (
            <p className="rsvp-form-errors">{errors.email.message}</p>
          )}
        </fieldset>

        <AttendingForm register={register} errors={errors} />

        <fieldset id={generateFieldsetId("stayingOnSite")}>
          <legend>{t("section.rsvp.form.stayingOnSite.legend")}</legend>
          <label>
            <input
              type="radio"
              id="stayingOnSiteYes"
              value="yes"
              aria-labelledby={generateFieldsetId("stayingOnSite")}
              {...register("stayingOnSite", {
                required: t("section.rsvp.form.stayingOnSite.required"),
              })}
            />
            <label htmlFor="stayingOnSiteYes">
              {t("section.rsvp.form.stayingOnSite.yes")}
            </label>
          </label>
          <label>
            <input
              type="radio"
              id="stayingOnSiteNo"
              value="no"
              aria-labelledby={generateFieldsetId("stayingOnSite")}
              {...register("stayingOnSite", {
                required: t("section.rsvp.form.stayingOnSite.required"),
              })}
            />
            <label htmlFor="stayingOnSiteNo">
              {t("section.rsvp.form.stayingOnSite.no")}
            </label>
          </label>
          {errors.stayingOnSite && (
            <p className="rsvp-form-errors">{errors.stayingOnSite.message}</p>
          )}
        </fieldset>
        <fieldset id={generateFieldsetId("dietaryRequirements")}>
          <legend>{t("section.rsvp.form.dietaryRequirements.legend")}</legend>
          <label>
            <input
              type="radio"
              id="dietaryRequirementsYes"
              value="yes"
              aria-labelledby={generateFieldsetId("dietaryRequirements")}
              {...register("dietaryRequirements", {
                required: t("section.rsvp.form.dietaryRequirements.required"),
              })}
            />
            <label htmlFor="dietaryRequirementsYes">
              {t("section.rsvp.form.dietaryRequirements.yes")}
            </label>
          </label>
          <label>
            <input
              type="radio"
              id="dietaryRequirementsNo"
              value="no"
              aria-labelledby={generateFieldsetId("dietaryRequirements")}
              {...register("dietaryRequirements", {
                required: t("section.rsvp.form.dietaryRequirements.required"),
              })}
            />
            <label htmlFor="dietaryRequirementsNo">
              {t("section.rsvp.form.dietaryRequirements.no")}
            </label>
          </label>
          {errors.dietaryRequirements && (
            <p className="rsvp-form-errors">
              {errors.dietaryRequirements.message}
            </p>
          )}
        </fieldset>
        {dietaryRequirements === "yes" && (
          <fieldset id={generateFieldsetId("dietaryDetails")}>
            <legend>{t("section.rsvp.form.dietaryDetails.legend")}</legend>
            <label htmlFor="dietaryDetailsTextarea">
              <textarea
                id="dietaryDetailsTextarea"
                placeholder={t("section.rsvp.form.dietaryDetails.placeholder")}
                aria-labelledby={generateFieldsetId("dietaryDetails")}
                {...register("dietaryDetails", {
                  required:
                    dietaryRequirements === "yes"
                      ? t("section.rsvp.form.dietaryDetails.required")
                      : false,
                })}
              />
            </label>
            {errors.dietaryDetails && (
              <p className="rsvp-form-errors">
                {errors.dietaryDetails.message}
              </p>
            )}
          </fieldset>
        )}
      </div>

      {/* Additional Guest Section */}
      <div className="rsvp-form-block">
        <fieldset id={generateFieldsetId("additionalGuests")}>
          <legend>{t("section.rsvp.form.otherGuests.legend")}</legend>
          <div className="button-group">
            <button
              type="button"
              onClick={() =>
                append({
                  type: "adult",
                  fullName: "",
                  dietaryRequirements: "no",
                })
              }
              className="rsvp-button add-guest"
            >
              {t("section.rsvp.form.otherGuests.addAdult")}
            </button>
            <button
              type="button"
              onClick={() =>
                append({
                  type: "child",
                  fullName: "",
                  dietaryRequirements: "no",
                })
              }
              className="rsvp-button add-guest"
            >
              {t("section.rsvp.form.otherGuests.addChild")}
            </button>
            <button
              type="button"
              onClick={() =>
                append({ type: "dog", fullName: "", dietaryRequirements: "no" })
              }
              className="rsvp-button add-guest"
            >
              {t("section.rsvp.form.otherGuests.addDog")}
            </button>
          </div>
        </fieldset>
      </div>

      <div className="rsvp-form-block">
        {fields.map((field, index) => (
          <fieldset key={field.id} id={generateFieldsetId(field.type)}>
            <legend>
              {index + 1}. {t(`section.rsvp.form.otherGuests.${field.type}`)}
              <FontAwesomeIcon
                className="remove-guest"
                icon={faTrashCan}
                onClick={() => remove(index)}
              />
            </legend>
            <fieldset id={generateFieldsetId(`${field.type}-guestName`)}>
              <legend>{t("section.rsvp.form.guestName.legend")}</legend>
              <input
                type="text"
                placeholder={t("section.rsvp.form.guestName.placeholder")}
                id={`otherGuestName${index}`}
                {...register(`otherGuests.${index}.fullName`, {
                  required: t("section.rsvp.form.guestName.required"),
                })}
              />
              {errors.otherGuests?.[index]?.fullName && (
                <p className="rsvp-form-errors">
                  {errors.otherGuests[index]?.fullName?.message}
                </p>
              )}
            </fieldset>
            <fieldset
              id={generateFieldsetId(`guest-dietaryRequirements-${index}`)}
            >
              <legend>
                {t("section.rsvp.form.dietaryRequirements.legend")}
              </legend>
              <label>
                <input
                  type="radio"
                  id={`guestDietaryRequirementsYes-${index}`}
                  value="yes"
                  aria-labelledby={generateFieldsetId(
                    `guest-dietaryRequirements-${index}`
                  )}
                  {...register(`otherGuests.${index}.dietaryRequirements`, {
                    required: t(
                      "section.rsvp.form.dietaryRequirements.required"
                    ),
                  })}
                />
                <label htmlFor={`guestDietaryRequirementsYes-${index}`}>
                  {t("section.rsvp.form.dietaryRequirements.yes")}
                </label>
              </label>
              <label>
                <input
                  type="radio"
                  id={`guestDietaryRequirementsNo-${index}`}
                  value="no"
                  aria-labelledby={generateFieldsetId(
                    `guest-dietaryRequirements-${index}`
                  )}
                  {...register(`otherGuests.${index}.dietaryRequirements`, {
                    required: t(
                      "section.rsvp.form.dietaryRequirements.required"
                    ),
                  })}
                />
                <label htmlFor={`guestDietaryRequirementsNo-${index}`}>
                  {t("section.rsvp.form.dietaryRequirements.no")}
                </label>
              </label>
              {errors?.otherGuests?.[index]?.dietaryRequirements && (
                <p className="rsvp-form-errors">
                  {errors.otherGuests[index]?.dietaryRequirements?.message}
                </p>
              )}
            </fieldset>
            {watch(`otherGuests.${index}.dietaryRequirements`) === "yes" && (
              <fieldset
                id={generateFieldsetId(`guest-dietaryDetails-${index}`)}
              >
                <legend>{t("section.rsvp.form.dietaryDetails.legend")}</legend>
                <label htmlFor={`guestDietaryDetailsInput-${index}`}>
                  <input
                    id={`guestDietaryDetailsInput-${index}`}
                    type="text"
                    placeholder={t(
                      "section.rsvp.form.dietaryDetails.placeholder"
                    )}
                    aria-labelledby={generateFieldsetId(
                      `guest-dietaryDetails-${index}`
                    )}
                    {...register(`otherGuests.${index}.dietaryDetails`, {
                      required:
                        watch(`otherGuests.${index}.dietaryRequirements`) ===
                        "yes",
                    })}
                  />
                </label>
                {errors?.otherGuests?.[index]?.dietaryDetails && (
                  <p className="rsvp-form-errors">
                    {errors.otherGuests[index]?.dietaryDetails?.message}
                  </p>
                )}
              </fieldset>
            )}
          </fieldset>
        ))}
      </div>

      <AdditionalInfoForm register={register} />

      <button
        type="submit"
        className="submit-button"
        id="rsvpSubmit"
        disabled={submitDisabled}
      >
        {t("section.rsvp.form.submit")}
      </button>
    </form>
  );
};

export default RsvpForm;
