import { motion, useInView } from "framer-motion";
import React, { useRef, CSSProperties } from "react";

interface SectionStyle {
  backgroundColor?: CSSProperties["backgroundColor"];
  backgroundImage?: CSSProperties["backgroundImage"];
  backgroundSize?: CSSProperties["backgroundSize"];
  backgroundRepeat?: CSSProperties["backgroundRepeat"];
}

interface SectionProps {
  id: string;
  title?: string;
  content: React.ReactNode;
  style?: SectionStyle;
}

const Section: React.FC<SectionProps> = ({ id, title, content, style }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  const sectionVariants = {
    hidden: { opacity: 0, y: 150 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <div
      className="section-container"
      style={style}
      id={id}
      aria-label={`${id}-section`}
    >
      <motion.section
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={sectionVariants}
      >
        {title && <h2 className="section-title">{title}</h2>}
        {content}
      </motion.section>
    </div>
  );
};

export default Section;
