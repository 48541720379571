import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import App from "./App";
import Footer from "./components/layout/Footer";
import Navbar from "./components/layout/Navbar";
import BackToTopButton from "./components/Buttons/BackToTopButton";

const I18nWrapper: React.FC = () => {
  const [language, setLanguage] = useState<string>(() => {
    const browserLang = navigator.language.split("-")[0];
    return ["en", "pl"].includes(browserLang) ? browserLang : "en";
  });

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "pl" : "en";
    setLanguage(newLanguage);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Navbar />
      <App />
      <BackToTopButton />
      <Footer
        title="Aga & Aaron"
        toggleLanguage={toggleLanguage}
        language={language}
      />
    </I18nextProvider>
  );
};

export default I18nWrapper;
