import React from "react";
import Section from "../Section";
import { useTranslation } from "react-i18next";

const Welcome: React.FC = () => {
  const { t } = useTranslation();

  const content = (
    <div className="section-flexbox">
      <div className="section-left section-text">
        <h2 className="section-title">{t("welcomeMessage.title")}</h2>
        <h3>{t("welcomeMessage.subtitle")}</h3>
        <h3>{t("welcomeMessage.description")}</h3>
      </div>
      <div className="section-right section-image">
        <img src="/images/venue-barn.png" alt="venue barn" />
      </div>
    </div>
  );

  return <Section id="welcome" content={content} />;
};

export default Welcome;
