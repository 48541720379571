import React from "react";
import Link from "../Link";
import { useTranslation } from "react-i18next";

const Navbar: React.FC = () => {
  const { t } = useTranslation();

  const links = [
    { label: t("navbar.about"), href: "#about" },
    { label: t("navbar.venue"), href: "#venue" },
    { label: t("navbar.rsvp"), href: "#rsvp" },
  ];

  return (
    <nav>
      <ul>
        {links.map((link, index) => (
          <React.Fragment key={link.href}>
            <li>
              <Link href={link.href} options={{ target: "_self" }}>
                {link.label}
              </Link>
            </li>
            {index < links.length - 1 && " / "}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
