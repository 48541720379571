import React from "react";
import { useTranslation } from "react-i18next";
import { UseFormRegister } from "react-hook-form";
import { FormValues } from "../../../../Types/Form.types";

interface AdditionalInfoProps {
  register: UseFormRegister<FormValues>;
}

const AdditionalInfoForm: React.FC<AdditionalInfoProps> = ({ register }) => {
  const { t } = useTranslation();

  return (
    <div className="rsvp-form-block">
      <fieldset>
        <legend>{t("section.rsvp.form.additionalInfo.legend")}</legend>
        <textarea
          id="additionalInfo"
          placeholder={t("section.rsvp.form.additionalInfo.placeholder")}
          {...register("additionalInfo")}
        />
      </fieldset>
    </div>
  );
};

export default AdditionalInfoForm;
