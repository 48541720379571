import React from "react";

const Logo = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="47"
    >
      <path
        d="M0 0 C15.51 0 31.02 0 47 0 C47 15.51 47 31.02 47 47 C31.49 47 15.98 47 0 47 C0 31.49 0 15.98 0 0 Z "
        fill="#F3F0E9"
        transform="translate(0,0)"
      />
      <path
        d="M0 0 C1.32 0 2.64 0 4 0 C4.66 1.32 5.32 2.64 6 4 C6.66 2.68 7.32 1.36 8 0 C8.66 0 9.32 0 10 0 C9.67 2.97 9.34 5.94 9 9 C9.94875 8.34 10.8975 7.68 11.875 7 C15 5 15 5 17 5 C17 5.66 17 6.32 17 7 C18.32 7.33 19.64 7.66 21 8 C21 9.32 21 10.64 21 12 C21.78375 11.484375 22.5675 10.96875 23.375 10.4375 C24.674375 9.7259375 24.674375 9.7259375 26 9 C26.66 9.33 27.32 9.66 28 10 C27.34 11.32 26.68 12.64 26 14 C26.66 14.66 27.32 15.32 28 16 C28 16.99 28 17.98 28 19 C28.99 18.67 29.98 18.34 31 18 C31.495 19.98 31.495 19.98 32 22 C23.04991995 30.35340805 15.19417341 34.79716335 2.83837891 34.51098633 C0.44626441 34.45523178 0.44626441 34.45523178 -3 35 C-5.39949273 37.40239781 -5.39949273 37.40239781 -7 40 C-7.66 40 -8.32 40 -9 40 C-9.33 40.99 -9.66 41.98 -10 43 C-11.32 43 -12.64 43 -14 43 C-14.05410687 41.22950311 -14.09284119 39.45853151 -14.125 37.6875 C-14.14820313 36.70136719 -14.17140625 35.71523438 -14.1953125 34.69921875 C-14 32 -14 32 -12 29 C-11.34 29 -10.68 29 -10 29 C-10.7425 27.6078125 -10.7425 27.6078125 -11.5 26.1875 C-13.5760548 21.34337213 -13.80900582 16.1792235 -13 11 C-11.4765625 8.66796875 -11.4765625 8.66796875 -10 7 C-9.34 9.31 -8.68 11.62 -8 14 C-7.34 12.68 -6.68 11.36 -6 10 C-5.34 10 -4.68 10 -4 10 C-4 10.66 -4 11.32 -4 12 C-3.34 12 -2.68 12 -2 12 C-1.855625 10.948125 -1.71125 9.89625 -1.5625 8.8125 C-1.12473034 5.84539451 -0.62195134 2.93205634 0 0 Z "
        fill="#B5A08B"
        transform="translate(14,4)"
      />
      <path
        d="M0 0 C0.66 0 1.32 0 2 0 C1.67373505 6.32278971 1.38495108 11.55464391 -2 17 C-1.236875 16.484375 -0.47375 15.96875 0.3125 15.4375 C1.199375 14.963125 2.08625 14.48875 3 14 C3.99 14.33 4.98 14.66 6 15 C4.26440562 18.057952 2.61527381 20.63379989 0 23 C1.65 23 3.3 23 5 23 C5 23.66 5 24.32 5 25 C10.52253469 24.22846942 15.59773114 23.45489079 20.5625 20.8125 C21.696875 20.214375 22.83125 19.61625 24 19 C24.66 19.33 25.32 19.66 26 20 C17.83735094 26.04036031 12.22762592 28.45595491 2.1015625 28.3515625 C-0.39586874 28.35641209 -0.39586874 28.35641209 -4 29 C-6.41695524 31.40690428 -6.41695524 31.40690428 -8 34 C-8.66 34 -9.32 34 -10 34 C-10.33 34.99 -10.66 35.98 -11 37 C-12.32 37 -13.64 37 -15 37 C-15.05410687 35.22950311 -15.09284119 33.45853151 -15.125 31.6875 C-15.14820313 30.70136719 -15.17140625 29.71523438 -15.1953125 28.69921875 C-15 26 -15 26 -13 23 C-12.34 23 -11.68 23 -11 23 C-11.7425 21.6078125 -11.7425 21.6078125 -12.5 20.1875 C-14.5760548 15.34337213 -14.80900582 10.1792235 -14 5 C-12.4765625 2.66796875 -12.4765625 2.66796875 -11 1 C-10.34 3.31 -9.68 5.62 -9 8 C-8.34 6.68 -7.68 5.36 -7 4 C-6.34 4 -5.68 4 -5 4 C-5 4.66 -5 5.32 -5 6 C-2.12579877 3.28395285 -2.12579877 3.28395285 0 0 Z "
        fill="#B58159"
        transform="translate(15,10)"
      />
      <path
        d="M0 0 C2 1.3125 2 1.3125 4 3 C4 3.99 4 4.98 4 6 C4.99 5.67 5.98 5.34 7 5 C7.33 6.32 7.66 7.64 8 9 C4.44619468 12.13571058 1.51539061 13.64538282 -3 15 C-4.11375 15.53625 -5.2275 16.0725 -6.375 16.625 C-10.64914175 18.24622618 -13.47781794 18.23800958 -18 18 C-18 17.34 -18 16.68 -18 16 C-19.65 16 -21.3 16 -23 16 C-22.67 15.01 -22.34 14.02 -22 13 C-19.10441559 11.5522078 -17.25811062 11 -14 11 C-13.67 11.99 -13.34 12.98 -13 14 C-8.545 13.505 -8.545 13.505 -4 13 C-3.74930087 10.62570704 -3.74930087 10.62570704 -4 8 C-4.99 7.01 -5.98 6.02 -7 5 C-4.69 3.35 -2.38 1.7 0 0 Z "
        fill="#DFCEBA"
        transform="translate(38,17)"
      />
      <path
        d="M0 0 C-0.45038771 4.84166786 -1.59972161 7.49668287 -5 11 C-5.66 11 -6.32 11 -7 11 C-7.33 11.99 -7.66 12.98 -8 14 C-9.32 14 -10.64 14 -12 14 C-12.6130031 4.12383901 -12.6130031 4.12383901 -10.5 0.25 C-6.71653015 -1.64173492 -4.10575359 -0.82115072 0 0 Z "
        fill="#7C5B39"
        transform="translate(12,33)"
      />
      <path
        d="M0 0 C0.66 0.33 1.32 0.66 2 1 C0.89764064 1.96041171 -0.20696624 2.91824407 -1.3125 3.875 C-2.23482422 4.67550781 -2.23482422 4.67550781 -3.17578125 5.4921875 C-5 7 -5 7 -8 9 C-7.505 9.391875 -7.01 9.78375 -6.5 10.1875 C-4.54406857 12.55091715 -4.75792027 14.01435003 -5 17 C-8.4019487 19.09350689 -11.05272412 19.17942163 -15 19 C-15 17.68 -15 16.36 -15 15 C-16.32 14.34 -17.64 13.68 -19 13 C-18.01 12.34 -17.02 11.68 -16 11 C-15.67 10.01 -15.34 9.02 -15 8 C-13.05884727 7.24327944 -11.11320739 6.49475485 -9.140625 5.82421875 C-5.74509498 4.51681577 -2.58807137 2.58807137 0 0 Z "
        fill="#C09676"
        transform="translate(40,13)"
      />
      <path
        d="M0 0 C0 1.32 0 2.64 0 4 C0.66 4.33 1.32 4.66 2 5 C-2.81585285 8.16040343 -8.15606472 11 -14 11 C-13.18610734 6.60497962 -11.28531168 3.32963277 -8.1875 0.125 C-5.16890139 -1.42742214 -3.21707485 -0.80426871 0 0 Z "
        fill="#D8BEA4"
        transform="translate(35,12)"
      />
      <path
        d="M0 0 C1.32 0 2.64 0 4 0 C5.30476082 4.15892513 6.12081885 7.65052131 6 12 C6.33 12.99 6.66 13.98 7 15 C5.875 18.0625 5.875 18.0625 4 21 C1.3828125 22.18359375 1.3828125 22.18359375 -1 23 C-0.79503906 22.19175781 -0.59007812 21.38351563 -0.37890625 20.55078125 C0.02521484 18.94783203 0.02521484 18.94783203 0.4375 17.3125 C0.70433594 16.25675781 0.97117187 15.20101563 1.24609375 14.11328125 C1.89897932 11.4171683 2.47941193 8.7242424 3 6 C2.34 6 1.68 6 1 6 C0.34 7.65 -0.32 9.3 -1 11 C-2.60080151 6.99799622 -1.29279692 3.95443764 0 0 Z "
        fill="#DBC0A4"
        transform="translate(14,4)"
      />
      <path
        d="M0 0 C2.35953094 2.35953094 2.49141413 3.77895617 3 7 C3.66 5.68 4.32 4.36 5 3 C5.66 3 6.32 3 7 3 C7 3.66 7 4.32 7 5 C7.66 5.33 8.32 5.66 9 6 C8.67 6.33 8.34 6.66 8 7 C7.60430892 9.01311449 7.25747809 11.03597208 6.9375 13.0625 C6.67259766 14.71958984 6.67259766 14.71958984 6.40234375 16.41015625 C6.26957031 17.26480469 6.13679687 18.11945312 6 19 C5.34 19 4.68 19 4 19 C4 18.34 4 17.68 4 17 C3.01 16.67 2.02 16.34 1 16 C-0.1671568 12.49852959 -0.13357453 9.67329964 0 6 C0.33 5.34 0.66 4.68 1 4 C0.34 4 -0.32 4 -1 4 C-0.67 2.68 -0.34 1.36 0 0 Z "
        fill="#CEA480"
        transform="translate(3,11)"
      />
      <path
        d="M0 0 C0.66 0.33 1.32 0.66 2 1 C-5.36154807 6.44754557 -10.58793381 9.49444541 -19.875 9.1875 C-20.65875 9.17396484 -21.4425 9.16042969 -22.25 9.14648438 C-24.16696782 9.11141789 -26.08357751 9.05762072 -28 9 C-28 8.67 -28 8.34 -28 8 C-26.02 8 -24.04 8 -22 8 C-22 7.34 -22 6.68 -22 6 C-23.32 6 -24.64 6 -26 6 C-25.34 4.68 -24.68 3.36 -24 2 C-24 2.66 -24 3.32 -24 4 C-22.35 4 -20.7 4 -19 4 C-19 4.66 -19 5.32 -19 6 C-13.47746531 5.22846942 -8.40226886 4.45489079 -3.4375 1.8125 C-2.303125 1.214375 -1.16875 0.61625 0 0 Z "
        fill="#B88E6E"
        transform="translate(39,29)"
      />
      <path
        d="M0 0 C0.33 0.99 0.66 1.98 1 3 C6.94 2.505 6.94 2.505 13 2 C8.89682187 6.10317813 5.32607156 7.11490961 -0.375 7.125 C-1.57125 7.08375 -2.7675 7.0425 -4 7 C-4 6.34 -4 5.68 -4 5 C-5.65 5 -7.3 5 -9 5 C-8.67 4.01 -8.34 3.02 -8 2 C-5.10441559 0.5522078 -3.25811062 0 0 0 Z "
        fill="#DCC4AA"
        transform="translate(24,28)"
      />
      <path
        d="M0 0 C0.99 0.33 1.98 0.66 3 1 C4.16796875 3.50390625 4.16796875 3.50390625 5.1875 6.5625 C5.53167969 7.57441406 5.87585938 8.58632812 6.23046875 9.62890625 C6.48441406 10.41136719 6.73835937 11.19382812 7 12 C6.01 12.495 6.01 12.495 5 13 C5 12.34 5 11.68 5 11 C4.34 11 3.68 11 3 11 C2.34 13.31 1.68 15.62 1 18 C0.67 18 0.34 18 0 18 C-0.83288874 11.74692761 -1.03863773 6.23182639 0 0 Z "
        fill="#DEC5AB"
        transform="translate(5,3)"
      />
      <path
        d="M0 0 C0.66 0 1.32 0 2 0 C1.85866501 1.77119803 1.71176792 3.54195287 1.5625 5.3125 C1.48128906 6.29863281 1.40007813 7.28476562 1.31640625 8.30078125 C1 11 1 11 0 14 C-1.65 14.33 -3.3 14.66 -5 15 C-5.59551568 9.28304945 -4.40162446 6.30016401 -1.2890625 1.50390625 C-0.65097656 0.75947266 -0.65097656 0.75947266 0 0 Z "
        fill="#AE7E5A"
        transform="translate(15,10)"
      />
      <path
        d="M0 0 C0.66 0.33 1.32 0.66 2 1 C0.89764064 1.96041171 -0.20696624 2.91824407 -1.3125 3.875 C-1.92738281 4.40867187 -2.54226562 4.94234375 -3.17578125 5.4921875 C-5 7 -5 7 -8 9 C-7.01 9.99 -6.02 10.98 -5 12 C-5.99 13.485 -5.99 13.485 -7 15 C-9.31 14.01 -11.62 13.02 -14 12 C-14 11.34 -14 10.68 -14 10 C-14.66 9.67 -15.32 9.34 -16 9 C-15.12988281 8.63648438 -15.12988281 8.63648438 -14.2421875 8.265625 C-6.57402943 5.02489349 -6.57402943 5.02489349 0 0 Z "
        fill="#BA9D84"
        transform="translate(40,13)"
      />
      <path
        d="M0 0 C0.66 0.33 1.32 0.66 2 1 C0.515 3.475 0.515 3.475 -1 6 C0.32 6.33 1.64 6.66 3 7 C2.34 7.66 1.68 8.32 1 9 C1.99 9.66 2.98 10.32 4 11 C3.01 12.485 3.01 12.485 2 14 C2 13.01 2 12.02 2 11 C0.35 11.33 -1.3 11.66 -3 12 C-3 9 -3 9 -2 8 C-2.66 7.67 -3.32 7.34 -4 7 C-2.92598892 4.08482708 -2.22189824 2.22189824 0 0 Z "
        fill="#CBA17D"
        transform="translate(22,17)"
      />
      <path
        d="M0 0 C0.99 0 1.98 0 3 0 C2.98259766 0.85271484 2.98259766 0.85271484 2.96484375 1.72265625 C2.90776806 6.60670484 3.18043228 11.18503965 4 16 C4.99 16 5.98 16 7 16 C6.67 16.66 6.34 17.32 6 18 C3 17 3 17 1.3125 14.625 C-0.47304056 9.69350703 -0.24928545 5.18883047 0 0 Z "
        fill="#A37959"
        transform="translate(1,15)"
      />
      <path
        d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.45276317 4.37462715 0.9451 6.08235 -1 9 C-1.99 9 -2.98 9 -4 9 C-4 8.34 -4 7.68 -4 7 C-4.99 7 -5.98 7 -7 7 C-7 6.01 -7 5.02 -7 4 C-6.34 4 -5.68 4 -5 4 C-5 4.66 -5 5.32 -5 6 C-4.34 6 -3.68 6 -3 6 C-2.814375 4.7934375 -2.814375 4.7934375 -2.625 3.5625 C-2.41875 2.716875 -2.2125 1.87125 -2 1 C-1.34 0.67 -0.68 0.34 0 0 Z "
        fill="#B7835A"
        transform="translate(12,24)"
      />
      <path
        d="M0 0 C0.33 0 0.66 0 1 0 C1.04898438 0.61488281 1.09796875 1.22976562 1.1484375 1.86328125 C1.22320312 2.67152344 1.29796875 3.47976562 1.375 4.3125 C1.44460937 5.11300781 1.51421875 5.91351563 1.5859375 6.73828125 C1.89564426 9.27224441 1.89564426 9.27224441 4 12 C4 13.32 4 14.64 4 16 C2.68 16.99 1.36 17.98 0 19 C0 12.73 0 6.46 0 0 Z "
        fill="#E6DFD6"
        transform="translate(0,17)"
      />
      <path
        d="M0 0 C1.32 0 2.64 0 4 0 C4.66 2.31 5.32 4.62 6 7 C5.34 7 4.68 7 4 7 C4 6.01 4 5.02 4 4 C0.98672434 5.50663783 0.38943382 8.03166411 -1 11 C-2.60080151 6.99799622 -1.29279692 3.95443764 0 0 Z "
        fill="#DFC9B1"
        transform="translate(14,4)"
      />
      <path
        d="M0 0 C0.66 0.66 1.32 1.32 2 2 C1.625 4.125 1.625 4.125 1 6 C-0.98 6.33 -2.96 6.66 -5 7 C-5.33 5.68 -5.66 4.36 -6 3 C-3 2 -3 2 0 2 C0 1.34 0 0.68 0 0 Z "
        fill="#DBBEA1"
        transform="translate(40,22)"
      />
      <path
        d="M0 0 C2.97 0.495 2.97 0.495 6 1 C6 2.32 6 3.64 6 5 C6.66 5.33 7.32 5.66 8 6 C6.35 6.33 4.7 6.66 3 7 C3 6.01 3 5.02 3 4 C1.68 3.67 0.36 3.34 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
        fill="#E0CEBB"
        transform="translate(29,11)"
      />
      <path
        d="M0 0 C0.99 0 1.98 0 3 0 C2.44271087 3.34373475 1.64826111 6.03312999 0 9 C-0.66 9 -1.32 9 -2 9 C-2.125 5.625 -2.125 5.625 -2 2 C-1.34 1.34 -0.68 0.68 0 0 Z "
        fill="#935C37"
        transform="translate(12,16)"
      />
      <path
        d="M0 0 C0.33 0 0.66 0 1 0 C1 1.65 1 3.3 1 5 C1.99 5.33 2.98 5.66 4 6 C3.3125 7.875 3.3125 7.875 2 10 C0.35 10.66 -1.3 11.32 -3 12 C-2.01 8.04 -1.02 4.08 0 0 Z "
        fill="#D6B696"
        transform="translate(16,15)"
      />
      <path
        d="M0 0 C0 0.66 0 1.32 0 2 C-0.66 2 -1.32 2 -2 2 C-1.67 2.99 -1.34 3.98 -1 5 C-4 7 -4 7 -7 7 C-6.22006468 2.47637516 -4.81844319 0 0 0 Z "
        fill="#E3CFB9"
        transform="translate(28,16)"
      />
      <path
        d="M0 0 C0.66 0 1.32 0 2 0 C2 0.66 2 1.32 2 2 C2.66 2.33 3.32 2.66 4 3 C3.67 3.33 3.34 3.66 3 4 C2.6068352 5.67884238 2.25914548 7.36850045 1.9375 9.0625 C1.67259766 10.44115234 1.67259766 10.44115234 1.40234375 11.84765625 C1.26957031 12.55792969 1.13679687 13.26820312 1 14 C0.67 14 0.34 14 0 14 C-0.14285714 6.57142857 -0.14285714 6.57142857 1 3 C0.34 2.67 -0.32 2.34 -1 2 C-0.67 1.34 -0.34 0.68 0 0 Z "
        fill="#D3B394"
        transform="translate(8,14)"
      />
      <path
        d="M0 0 C0.66 0.33 1.32 0.66 2 1 C-8.36893204 10 -8.36893204 10 -11 10 C-9.78389183 6.35167549 -8.68563692 5.88114477 -5.5 3.875 C-2.5968522 2.13228203 -2.5968522 2.13228203 0 0 Z "
        fill="#847F6C"
        transform="translate(40,13)"
      />
      <path
        d="M0 0 C3 1 3 1 4.25 2.8125 C5.11637742 5.33943414 4.88492145 6.52221994 4 9 C4 8.01 4 7.02 4 6 C2.02 6.99 2.02 6.99 0 8 C0 5.36 0 2.72 0 0 Z "
        fill="#E4D3BE"
        transform="translate(5,3)"
      />
      <path
        d="M0 0 C0 4 0 4 -1.3125 5.484375 C-2.875 6.65625 -4.4375 7.828125 -6 9 C-6.25 6.75 -6.25 6.75 -6 4 C-2.75675676 0 -2.75675676 0 0 0 Z "
        fill="#35331A"
        transform="translate(6,38)"
      />
      <path
        d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.67 1.99 1.34 2.98 1 4 C0.34 4 -0.32 4 -1 4 C-1.33 4.99 -1.66 5.98 -2 7 C-2.99 7 -3.98 7 -5 7 C-4.1875 5.0625 -4.1875 5.0625 -3 3 C-2.01 2.67 -1.02 2.34 0 2 C0 1.34 0 0.68 0 0 Z "
        fill="#58593E"
        transform="translate(6,40)"
      />
      <path
        d="M0 0 C0.33 0 0.66 0 1 0 C1.1875 3.375 1.1875 3.375 1 7 C0.01 7.66 -0.98 8.32 -2 9 C-2.25 3.375 -2.25 3.375 0 0 Z "
        fill="#767761"
        transform="translate(2,33)"
      />
      <path
        d="M0 0 C-0.33 0.33 -0.66 0.66 -1 1 C1.97 1.495 1.97 1.495 5 2 C5 2.33 5 2.66 5 3 C2.36 3 -0.28 3 -3 3 C-3 2.34 -3 1.68 -3 1 C-4.65 1 -6.3 1 -8 1 C-4.93251126 -0.86240388 -3.40254489 -1.2151946 0 0 Z "
        fill="#D8B796"
        transform="translate(23,32)"
      />
      <path
        d="M0 0 C2.375 -0.1875 2.375 -0.1875 5 0 C5.66 0.99 6.32 1.98 7 3 C4.36 3.33 1.72 3.66 -1 4 C-0.67 2.68 -0.34 1.36 0 0 Z "
        fill="#AD7E5B"
        transform="translate(27,26)"
      />
      <path
        d="M0 0 C0.66 0 1.32 0 2 0 C2 0.66 2 1.32 2 2 C3.32 2 4.64 2 6 2 C6 2.66 6 3.32 6 4 C2.37673261 5.2077558 1.35853631 4.54311128 -2 3 C-1.34 2.01 -0.68 1.02 0 0 Z "
        fill="#D2C8BA"
        transform="translate(33,20)"
      />
      <path
        d="M0 0 C0.66 0.33 1.32 0.66 2 1 C1.6875 3.375 1.6875 3.375 1 6 C0.01 6.66 -0.98 7.32 -2 8 C-2 5.69 -2 3.38 -2 1 C-1.34 0.67 -0.68 0.34 0 0 Z "
        fill="#C6966E"
        transform="translate(12,24)"
      />
      <path
        d="M0 0 C0.66 0.66 1.32 1.32 2 2 C1.9765625 4.6015625 1.9765625 4.6015625 1.625 7.625 C1.51414062 8.62789062 1.40328125 9.63078125 1.2890625 10.6640625 C1.19367187 11.43492188 1.09828125 12.20578125 1 13 C0.67 13 0.34 13 0 13 C-0.125 6.25 -0.125 6.25 1 4 C0.34 4 -0.32 4 -1 4 C-0.67 2.68 -0.34 1.36 0 0 Z "
        fill="#BC906E"
        transform="translate(3,11)"
      />
    </svg>
  );
};

export default Logo;
