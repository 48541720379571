import React from "react";
import Section from "../Section";
import { useTranslation } from "react-i18next";
import { LatLngExpression } from "leaflet";
import Map from "../Map";

const Venue: React.FC = () => {
  const { t } = useTranslation();

  const position: LatLngExpression = [52.37379890898457, 22.065643203897213];

  const content = (
    <>
      <div className="section-flexbox">
        <div className="section-left section-text">
          <h2 className="section-title">{t("section.venue.heading")}</h2>
          <h3>{t("section.venue.paragraphOne")}</h3>
          <p>{t("section.venue.paragraphTwo")}</p>
          <p>{t("section.venue.paragraphThree")}</p>
        </div>
        <div className="section-right section-image">
          <img
            src="/images/venue-accommodation.png"
            alt="venue accommodation"
          />
        </div>
      </div>
      <div
        className="section-full-width section-map"
        style={{ marginTop: "8px" }}
      >
        <Map
          position={position}
          iconUrl="/images/marker-icon.png"
          popupText={t("section.venue.map.popupText")}
        />
      </div>
    </>
  );

  return <Section id="venue" content={content} />;
};

export default Venue;
