import emailjs from "emailjs-com";
import { i18n, TFunction } from "i18next";
import { toSentenceCase, toTitleCase } from "../utils/stringHelpers";
import { FormValues } from "../Types/Form.types";

export const sendEmail = async (
  data: FormValues,
  t: TFunction,
  i18nLanguage: i18n
): Promise<void> => {
  const attendingMessage: string =
    data.attending === "yes"
      ? t("section.rsvp.attendingMessage")
      : t("section.rsvp.notAttendingMessage");

  const translatedAttending =
    data.attending === "yes"
      ? t("section.rsvp.form.attending.yes")
      : t("section.rsvp.form.attending.no");

  const translatedStayingOnSite =
    data.stayingOnSite === "yes"
      ? t("section.rsvp.form.stayingOnSite.yes")
      : t("section.rsvp.form.stayingOnSite.no");

  const translatedDietaryRequirements =
    data.dietaryRequirements === "yes"
      ? t("section.rsvp.form.dietaryRequirements.yes")
      : t("section.rsvp.form.dietaryRequirements.no");

  const guestDetails =
    data.otherGuests && data.otherGuests.length > 0
      ? data.otherGuests.map((guest) => {
          const dietaryDetails = guest.dietaryDetails
            ? ` - ${toSentenceCase(guest.dietaryDetails)}`
            : "";
          return `${toTitleCase(guest.fullName)} (${t(
            `section.rsvp.form.otherGuests.${guest.type}`
          )}) | ${t("section.rsvp.form.dietaryRequirements.legend")} ${t(
            `section.rsvp.form.dietaryRequirements.${guest.dietaryRequirements}`
          )}${dietaryDetails}`;
        })
      : [t("section.rsvp.form.otherGuests.noGuests")];

  const templateParams = {
    fullName: toTitleCase(data.fullName),
    email: data.email,
    attending: toSentenceCase(translatedAttending),
    stayingOnSite: toSentenceCase(translatedStayingOnSite),
    dietaryRequirements: toSentenceCase(translatedDietaryRequirements),
    dietaryDetails:
      toSentenceCase(data.dietaryDetails) ||
      t("section.rsvp.form.dietaryRequirements.default"),
    otherGuests: guestDetails,
    additionalInfo:
      toSentenceCase(data.additionalInfo) ||
      t("section.rsvp.form.dietaryRequirements.default"),
    attendingMessage,
  };

  const serviceId: string = process.env.REACT_APP_SERVICE_ID ?? "";
  const templateId: string =
    (i18nLanguage.language === "pl"
      ? process.env.REACT_APP_PL_RSVP_TEMPLATE_ID
      : process.env.REACT_APP_EN_RSVP_TEMPLATE_ID) ?? "";
  const publicApiKey: string = process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY ?? "";

  if (!serviceId || !templateId || !publicApiKey) {
    throw new Error(t("section.rsvp.emailConfigErrorMessage"));
  }

  try {
    await emailjs.send(serviceId, templateId, templateParams, publicApiKey);
  } catch (error) {
    console.error("Error sending email:", error);
    throw new Error(t("section.rsvp.emailErrorMessage"));
  }
};
