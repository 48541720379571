import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

interface LinkProps {
  href: string;
  children?: React.ReactNode;
  options?: {
    target?: string;
    rel?: string;
  };
}

const Link: React.FC<LinkProps> = ({ href, children, options }) => {
  const { target = "_blank", rel = "noopener noreferrer" } = options || {};

  return (
    <a href={href} target={target} rel={rel}>
      {children ? (
        children
      ) : (
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      )}
    </a>
  );
};

export default Link;
