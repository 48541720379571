import React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  ScaleControl,
} from "react-leaflet";
import { LatLngExpression, Icon } from "leaflet";
import Link from "./Link";

interface MapComponentProps {
  position: LatLngExpression;
  iconUrl: string;
  iconHeight?: number;
  iconWidth?: number;
  popupText: string;
}

const Map: React.FC<MapComponentProps> = ({
  position,
  iconUrl,
  iconHeight = 64,
  iconWidth = 64,
  popupText,
}) => {
  const mapIcon = new Icon({
    iconUrl,
    iconSize: [iconHeight, iconWidth],
    iconAnchor: [iconWidth / 2, iconHeight],
    popupAnchor: [0, -iconWidth],
  });

  return (
    <MapContainer
      center={position}
      zoom={9}
      style={{ height: "400px", width: "100%" }}
      scrollWheelZoom={false}
      doubleClickZoom={false}
      touchZoom={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={position} icon={mapIcon}>
        <Popup>
          <p className="popup-text">
            {popupText}
            <Link href="https://folwarkruchenka.pl" />
          </p>
        </Popup>
      </Marker>
      <ScaleControl position="topright" />
    </MapContainer>
  );
};

export default Map;
