import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface SecurityQuestionProps {
  onAccessGranted: () => void;
  onAccessDenied: (error: string) => void;
}

const SecurityQuestion: React.FC<SecurityQuestionProps> = ({
  onAccessGranted,
  onAccessDenied,
}) => {
  const { t } = useTranslation();
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState("");

  const correctAnswerEn =
    process.env.REACT_APP_SECURITY_ANSWER_EN?.toLowerCase();
  const correctAnswerPl =
    process.env.REACT_APP_SECURITY_ANSWER_PL?.toLowerCase();

  const handleCheckAnswer = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      answer.toLowerCase() === correctAnswerEn ||
      answer.toLowerCase() === correctAnswerPl
    ) {
      onAccessGranted();
    } else {
      const errorMsg = t("section.rsvp.incorrectAnswer");
      onAccessDenied(errorMsg);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <form className="security-question" onSubmit={handleCheckAnswer}>
      <p>
        {t("section.rsvp.securityQuestionExplanation")}...
      </p>
      <br />
      <p>{t("section.rsvp.securityQuestion")}</p>
      <input
        type="text"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        placeholder={t("section.rsvp.answerPlaceholder")}
      />
      <button type="submit" className="submit-button">
        {t("section.rsvp.submitAnswer")}
      </button>
      {error && <p className="rsvp-form-errors visible">{error}</p>}
    </form>
  );
};

export default SecurityQuestion;
