import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { inject } from "@vercel/analytics";
import I18nWrapper from "./I18nWrapper";

// Vercel analytics
inject();

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOMClient.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <I18nWrapper />
  </React.StrictMode>
);
