import React from "react";
import Section from "../Section";
import Link from "../Link";
import { useTranslation } from "react-i18next";

const About: React.FC = () => {
  const { t } = useTranslation();

  const sectionStyle = {
    backgroundColor: "var(--sand)",
  };

  const content = (
    <div className="section-flexbox">
      <div className="section-left section-image">
        <img src="/images/aga-and-aaron.png" alt="Aga and Aaron" />
      </div>
      <div className="section-right section-text">
        <h2 className="section-title">{t("section.about.heading")}</h2>
        <h3>{t("section.about.paragraphOne")}</h3>
        <p>
          <strong>{t("section.about.dateAndTime.label")} </strong>
          {t("section.about.dateAndTime.content")}
        </p>
        <p>
          <strong>{t("section.about.location.label")} </strong>
          {t("section.about.location.content")}{" "}
          <Link href="https://folwarkruchenka.pl/" />
        </p>
        <p>{t("section.about.paragraphTwo")}</p>
        <p>{t("section.about.paragraphThree")}</p>
        <p>{t("section.about.paragraphFour")}</p>
      </div>
    </div>
  );

  return <Section id="about" content={content} style={sectionStyle} />;
};

export default About;
