import React from "react";
import "./styles/index.scss";
import * as Content from "./components/Content";
import { motion, useScroll, useSpring } from "framer-motion";

export default function App() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <>
      <motion.div
        role="progressbar"
        className="progress-bar"
        style={{ scaleX }}
      />
      <Content.Welcome />
      <Content.About />
      <Content.Venue />
      <Content.Rsvp />
    </>
  );
}
