const toSentenceCase = (text: string | undefined): string => {
  if (!text || text === undefined) {
    return "";
  }

  text = text.trim();

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const toTitleCase = (text: string | undefined): string => {
  if (!text) {
    return "";
  }

  return text
    .trim()
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export { toSentenceCase, toTitleCase };
